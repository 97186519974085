﻿import m from "mithril";
import i18n from "../../../shared/i18n/i18n";
import Component_Unit_Form_Editor from "./component_unit_form_editor";
import Component_Unit_Form_Settings from "./component_unit_form_settings";

let loading = false;
let tab = "editor";
let unit = null;

const Page_Unit_Form = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", i18n.t("form_unit")),
                        m(".toolbar-menu", [
                            m("div", {
                                class: tab === "editor" ? "active" : "", onclick: function () {
                                    tab = "editor";
                                }
                            }, i18n.t("editor")),
                            m("div", {
                                class: tab === "settings" ? "active" : "", onclick: function () {
                                    tab = "settings";
                                }
                            }, i18n.t("settings"))
                        ])
                    ])
                ])
            ]),
            !loading ? [
                unit ? [
                    tab === "editor" ? m(Component_Unit_Form_Editor, {unit: unit})
                        : tab === "settings" ? m(Component_Unit_Form_Settings, {unit: unit.properties})
                            : ""
                ] : m(".text-gray padding", i18n.t("invalid_unit"))
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default Page_Unit_Form;
