﻿import m from "mithril";
import i18n from "../../../shared/i18n/i18n";
import CertificateEditorInput from "./CertificateEditorInput";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import Auth from "../../../shared/auth";
import CertificateEditorToolbar from "./CertificateEditorToolbar";
import Modal_Unit_Certificate_Preview from "./modal_unit_certificate_preview";

let loading = false;
let uploading = false;
let saving = false;
let unit = null;
let certInput = null;
let history = [];
let tab = "editor";
let originalUnit;

function loadCertificate() {
    certInput = m(CertificateEditorInput, {
        content: {
            header: unit.header ? unit.header : "",
            body: unit.body ? unit.body : "",
            footer: unit.footer ? unit.footer : ""
        },
        bg: unit.imagePath,
        id: unit.id,
        saving: saving
    });
}

function saveCertificate() {
    saving = true;

    let editorContent = certInput.state.getContent();
    unit.header = editorContent.header;
    unit.body = editorContent.body;
    unit.footer = editorContent.footer;
    let newBg = document.querySelector(".certificate-bg-input").files[0];

    Requester.post(Gateway.admin + "units/certificate/" + unit.id + "/content", unit)
        .then(function (result) {
            if (newBg)
                uploadCertificateBg();
            else {
                saving = false;
                Toasts.add("success", i18n.t("saved_changes"), "");
            }
        })
        .catch(function (error) {
            saving = false;
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function uploadCertificateBg() {
    saving = false;
    uploading = true;

    const d = new FormData();
    const file = document.querySelector(".certificate-bg-input").files[0];

    if (!file)
        Toasts.add("error", i18n.t("select_file"), "");

    if (file.size / 2048 / 2048 > 1)
        Toasts.add("error", i18n.t("file_too_big"), i18n.t("file_less_than") + "1mb");

    d.append("file", file, file.name);

    Requester.upload(Gateway.admin + "units/certificate/" + unit.id + "/upload-image", d)
        .then(function () {
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            document.querySelector(".certificate-bg-input").value = null;
            uploading = false;
        });
}

function previewCertificate() {
    ModalManager.open(i18n.t("certificate_preview"), ["modal-big"], Modal_Unit_Certificate_Preview, {url: Gateway.admin + "units/certificate/" + unit.id + "/preview"});
}


const Component_Unit_Certificate_Editor = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        originalUnit = JSON.parse(JSON.stringify(unit));
        loadCertificate();
    },
    view: function () {
        return [
            m(".content-box", [
                Auth.validateRights(["unit_certificate_edit"], [
                    m(".toolbar", [
                        m(".toolbar-section", {class: saving ? "disable-buttons" : "", style: "flex-wrap: wrap;"}, [
                            m("button.with-icon btn-cta", {onclick: saveCertificate}, [m("i.icon-check"), i18n.t("save_changes")]),
                            m("button.with-icon", {onclick: previewCertificate}, [m("i.icon-visible"), i18n.t("preview")]),
                            m(".dropdown auto-width",
                                m("button.with-icon", [
                                    m("i.icon-rotate"),
                                    unit.isPortrait ? i18n.t("portrait") : i18n.t("landscape"),
                                    m("i.icon-back rotate-270 ml-2")
                                ]),
                                m(".dropdown-list", [
                                    m("div", {
                                        class: unit.isPortrait ? "active" : "", onclick: function () {
                                            unit.isPortrait = true;
                                            setTimeout(function () {
                                                certInput.state.setScale();
                                            }, 50);
                                        }
                                    }, i18n.t("portrait")),
                                    m("div", {
                                        class: !unit.isPortrait ? "active" : "", onclick: function () {
                                            unit.isPortrait = false;
                                            setTimeout(function () {
                                                certInput.state.setScale();
                                            }, 50);
                                        }
                                    }, i18n.t("landscape"))
                                ])
                            ),
                            m(".dropdown auto-width",
                                m("button.with-icon", [
                                    m("i.icon-code"),
                                    i18n.t("insert_data"),
                                    m("i.icon-back rotate-270 ml-2")
                                ]),
                                m(".dropdown-list", [
                                    //m("div", { onclick: function () { certInput.state.getEditor().insertHTML("users_name"); } }, "users_name"),
                                    m("div", {
                                        onclick: function () {
                                            certInput.state.getEditor().insertHTML("first_name");
                                        }
                                    }, i18n.t("first_name")),
                                    m("div", {
                                        onclick: function () {
                                            certInput.state.getEditor().insertHTML("last_name");
                                        }
                                    }, i18n.t("last_name")),
                                    m("div", {
                                        onclick: function () {
                                            certInput.state.getEditor().insertHTML("full_name");
                                        }
                                    }, i18n.t("full_name")),
                                    m("div", {
                                        onclick: function () {
                                            certInput.state.getEditor().insertHTML("course_name");
                                        }
                                    }, i18n.t("course_name")),
                                    m("div", {
                                        onclick: function () {
                                            certInput.state.getEditor().insertHTML("unit_name");
                                        }
                                    }, i18n.t("unit_name")),
                                    m("div", {
                                        onclick: function () {
                                            certInput.state.getEditor().insertHTML("unit_description");
                                        }
                                    }, i18n.t("unit_description")),
                                    //m("div", { onclick: function () { certInput.state.getEditor().insertHTML("enrolment_id"); } }, "custom_data"),
                                    m("div", {
                                        onclick: function () {
                                            certInput.state.getEditor().insertHTML("completion_date");
                                        }
                                    }, i18n.t("completion_date")),
                                    m("div", {
                                        onclick: function () {
                                            certInput.state.getEditor().insertHTML("enrolment_id");
                                        }
                                    }, i18n.t("enrolment_id"))
                                ])
                            ),
                            m(CertificateEditorToolbar, {input: certInput, id: unit.id})
                        ])
                    ])
                ]),
                !loading ? [
                    unit ? [
                        m(".certificate-preview", {class: unit.isPortrait ? i18n.t("portrait") : i18n.t("landscape")}, unit && certInput ? certInput : "")
                    ] : m(".text-gray padding", i18n.t("unable_to_load_unit"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};
export default Component_Unit_Certificate_Editor;
