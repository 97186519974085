﻿import m from "mithril";
import Toasts from "../../shared/toasts";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import ModalManager from "../../shared/modal";
import i18n from "../../shared/i18n/i18n";
import FormUtilities from "../../shared/utilities/form_utilities";

let saving, callback, loading, hasSsoAccount, modalId;
const emails = [];

function addEmail() {
    let emailField = document.getElementById("new-user-add-email-field");
    loading = true;
    hasSsoAccount = false;
    Requester.get(Gateway.admin + "users/check-sso-email?email=" + encodeURIComponent(emailField.value))
        .then(function (result) {
            if(!result.value) {
                Toasts.add("error", i18n.t("error"), i18n.t("HasNoSsoAccount"))
            }
            hasSsoAccount = result.value;
        }).catch(function (error) {
        hasSsoAccount=false;
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
        if (hasSsoAccount && emailField.value && FormUtilities.validateEmail(emailField.value) && emails.indexOf(emailField.value) === -1) {
            emails.push(emailField.value);
            emailField.value = "";
            emailField.classList.remove("has-error");
        } else {
            emailField.classList.add("has-error");
        }
    })

}

function removeEmail(email) {
    let index = emails.indexOf(email);
    if (index > -1)
        emails.splice(index, 1);
}

function inviteUsers() {
    if (!emails.length > 0)
        Toasts.add("error", i18n.t("cant_send"), i18n.t("at_least_one_email"));
    else {
        saving = true;
        Requester.post(Gateway.admin + "users/invite", emails)
            .then(function () {
                Toasts.add("success", i18n.t("sent"), "");
                //Logger.add("success", "Invite Users", emails.length + " invitations sent");
                callback();
                ModalManager.close(modalId);
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                saving = false;
            });
    }
}

const Modal_User_Invite = {
    oninit: function (vnode) {
        callback = vnode.attrs.callback;
    },
    view: function () {
        return [
            m(".form-section with-button", [
                m("input[type=email]#new-user-add-email-field[placeholder=example@email.com]", {
                    onkeyup: function (e) {
                        if (e.keyCode === 13) {
                            addEmail();
                        }
                    }
                }),
                m("button.active", {onclick: addEmail}, "+")
            ]),
            emails.map(function (email) {
                return [
                    m(".form-section", [
                        m("button.btn-text", {
                            onclick: function () {
                                removeEmail(email);
                            }
                        }, "x"),
                        email
                    ])
                ];
            }),
            m(".form-buttons", [
                !saving && !(loading) ? m("button", {onclick: inviteUsers}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_User_Invite;
