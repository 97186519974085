﻿import m from "mithril";
import Auth from "../../shared/auth";
import i18n from "../../shared/i18n/i18n";
import Component_Organisation_Details from "./component_organisation_details";

let loading = false;
let tab = "details";
let currentPage;

const Page_AdminOrganisation = {
    oninit: function (vnode) {
        currentPage = "settings";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", i18n.t("organisation")),
                        m(".toolbar-menu", [
                            Auth.validateRights(["org_view"], m("div", {
                                class: tab === "details" ? "active" : "",
                                onclick: function () {
                                    tab = "details";
                                }
                            }, i18n.t("details")))
                        ])
                    ])
                ])
            ]),
            !loading ? [
                tab === "details" ? m(Component_Organisation_Details)
                    : ""
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default Page_AdminOrganisation;
