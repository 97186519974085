﻿import m from "mithril";
import SessionController from "../session";
import OrgFileService from "../storage/org_file_service";

const AuthLogo = {
    view: function () {
        return [
            m(".form-logo-container",
                SessionController.session.environment ? [
                    SessionController.session.environment.logoName ? m("img", {src: OrgFileService.getLogoPath()})
                        : SessionController.session.environment.name || i18n.t("no_organisation_exists")
                ] : m("img", {src: SessionController.session.environment.storageUrl + "/assets/img/logo.png"})
            )
        ];
    }
}

export default AuthLogo;
