﻿import m from "mithril";
const {Sortable} = require("sortablejs")
import Component_Table_Footer from "../../shared/components/component_table_footer";
import Auth from "../../shared/auth";
import Requester from "../../shared/request";
import Toasts from "../../shared/toasts";
import Gateway from "../../shared/app_settings";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";
import Modal_Course_New from "./modal_course_new";
import RouterService from "../../shared/services/router.service";

let loading, filter, search, courses, selected = [],
    total = 0, limit = 25, offset = 0, orderBy;

let currentPage = "courses";

function submitPagination(lim, off) {
    limit = lim;
    offset = off;
    loadCourses();
}

function loadCourses() {
    loading = true;
    courses = null;
    selected = [];

    const params = [];
    if (filter)
        params.push("filter=" + filter);
    if (search)
        params.push("search=" + search);
    if (orderBy)
        params.push("orderBy=" + orderBy);

    params.push("limit=" + limit);
    params.push("offset=" + offset * limit);

    Requester.get(Gateway.admin + "courses" + (params.length > 0 ? "?" + params.join("&") : ""))
        .then(function (result) {
            total = result.properties.totalCount;
            courses = result.entities;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function reorderCourse(id, oldIndex, newIndex) {
    Requester.post(Gateway.admin + "courses/" + id + "/reorder", {id: id, index: newIndex})
        .then(function () {
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function newCourse() {
    ModalManager.open(i18n.t("create_course"), [], Modal_Course_New);
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (courses) {
            for (let i = 0; i < courses.length; i++) {
                selected.push(courses[i].properties.id);
            }
        }
    }
}

function toggleSelected(id) {
    let index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}

function activateCourses() {
    loading = true;
    Requester.post(Gateway.admin + "courses/activate", selected)
        .then(function () {
            loadCourses();
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("cant_save"), error.response.properties.detail);
            loading = false;
        });
}

function deactivateCourses() {
    loading = true;
    Requester.post(Gateway.admin + "courses/deactivate", selected)
        .then(function () {
            loadCourses();
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("cant_save"), error.response.properties.detail);
            loading = false;
        });
}


function orderByCourseName() {
    if (orderBy === "courseName") {
        orderBy = "courseNameDesc";
    } else if (orderBy === "courseNameDesc") {
        orderBy = null;
    } else {
        orderBy = "courseName";
    }

    loadCourses();
}


const Page_Courses = {

    oninit: function () {
        loadCourses();
        currentPage = "courses";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("courses"))
                    ]),
                    m(".toolbar-section", [
                        Auth.validateRights(["courses_create"], m("button.with-icon btn-cta", {onclick: newCourse}, [m("i.icon-plus"), i18n.t("create_course")]))
                    ])
                ])
            ]),
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        Auth.validateRights(["courses_edit_settings"], [
                            m(".dropdown", {class: selected.length === 0 ? "disabled" : ""},
                                m("button.with-icon pl-2", [
                                    m("i.icon-visible"),
                                    i18n.t("change_status"),
                                    m("i.icon-back rotate-270 ml-2")
                                ]),
                                m(".dropdown-list", [
                                    m("div", {onclick: activateCourses}, i18n.t("active")),
                                    m("div", {onclick: deactivateCourses}, i18n.t("inactive"))
                                ])
                            ),
                            selected && selected.length > 0 ? m("span.ml-1", [selected.length, i18n.t(" space_selected")]) : ""
                        ])
                    ]),
                    m(".toolbar-section", [
                        m(".dropdown",
                            m("button.with-icon pl-2", [
                                filter === "active" ? i18n.t("active") : filter === "inactive" ? i18n.t("inactive") : i18n.t("all_courses"),
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: !filter ? "active" : "", onclick: function () {
                                        filter = null;
                                        loadCourses();
                                    }
                                }, i18n.t("all_my_courses")),
                                m("div", {
                                    class: filter === "active" ? "active" : "", onclick: function () {
                                        filter = "active";
                                        loadCourses();
                                    }
                                }, i18n.t("active")),
                                m("div", {
                                    class: filter === "inactive" ? i18n.t("active") : "", onclick: function () {
                                        filter = "inactive";
                                        loadCourses();
                                    }
                                }, i18n.t("inactive"))
                            ])
                        ),
                        m(".has-icon-right", [
                            m("input[type=search]", {
                                oninput: function () {
                                    search = this.value;
                                }, onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        loadCourses();
                                    }
                                }, value: search, placeholder: i18n.t("search")
                            }),
                            m("i.icon-x c-hand", {
                                onclick: function () {
                                    search = null;
                                    loadCourses();
                                }
                            }),
                            m("i.icon-search c-hand", {
                                onclick: function () {
                                    loadCourses();
                                }
                            })
                        ])
                    ])
                ]),
                m(".flex-column", [
                    !loading ? [
                        courses ? [
                            m(".table-wrapper flex-auto", [
                                courses.length > 0 ? [
                                    m("table.full-width", {class: Auth.validateRights(["courses_edit_settings"]) ? "sortable-table" : ""}, [
                                        m("thead",
                                            m("tr", [
                                                m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))),
                                                m("th.c-hand", {onclick: orderByCourseName}, [i18n.t("name"), m("i.icon-forward align-middle", {class: orderBy === "courseName" ? "rotate-90" : orderBy === "courseNameDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                                m("th", i18n.t("latest_version")),
                                                m("th", i18n.t("active")),
                                                Auth.validateRights(["courses_edit_settings"], m("th", "")),
                                            ])
                                        ),
                                        m("tbody", {
                                            oncreate: Auth.validateRights(["courses_edit_settings"], function (v) {
                                                Sortable.create(v.dom, {
                                                    draggable: "tr",
                                                    handle: ".icon-arrange",
                                                    onEnd: function (e) {
                                                        if (e.oldIndex !== e.newIndex) {
                                                            reorderCourse(e.item.getAttribute("data-id"), e.oldIndex, e.newIndex);
                                                        }
                                                    }
                                                });
                                            })
                                        }, courses.map(function (course) {
                                            return m("tr", {"data-id": course.properties.id}, [
                                                m("td.list-checkbox c-hand pl-1 pr-1", {
                                                    onclick: function () {
                                                        toggleSelected(course.properties.id);
                                                    }, style: "width: 18px;"
                                                }, m("i", {class: selected.indexOf(course.properties.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})),
                                                m("td.text-bolder", m(m.route.Link, {href: RouterService.getCourseUrl(course.properties.id)}, course.properties.name)),
                                                m("td", m(m.route.Link, {href: RouterService.getCourseUrl(course.properties.id)}, course.properties.version)),
                                                m("td", m(m.route.Link, {
                                                    href: RouterService.getCourseUrl(course.properties.id),
                                                    tabindex: "-1"
                                                }, course.properties.isActive ? m("i.icon-check") : "")),
                                                Auth.validateRights(["courses_edit_settings"], m("td", m("i.icon-arrange")))
                                            ])
                                        }))
                                    ])
                                ] : m(".text-gray padding", i18n.t("no_courses"))
                            ]),
                            m(Component_Table_Footer, {
                                callback: submitPagination,
                                total: total,
                                limit: limit,
                                offset: offset,
                                loading: loading,
                                item: "courses"
                            })
                        ] : m(".text-gray padding", i18n.t("unable_to_load_courses"))
                    ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
                ])
            ])
        ];
    }
};

export default Page_Courses;
