﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import i18n from "../../shared/i18n/i18n";
import Toasts from "../../shared/toasts";


const UnitSettingsUtilities = {
    toggleActiveStatus: function (ids, activate, loading) {
        loading = true;
        Requester.post(Gateway.admin + "units/" + (activate ? "activate" : "deactivate"), ids)
            .then(function () {
                Toasts.add("success", i18n.t("saved_changes"), "");
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                loading = false;
            });
    }
};
export default UnitSettingsUtilities;
