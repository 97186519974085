﻿import m from "mithril";

let colour, lightText, callback, showSwatches;
const swatches = ["#D9B47F", "#FAA65A", "#F56767", "#923467", "#D881A6", "#8C67DD",
    "#466AFB", "#2F53AA", "#80B7D5", "#399F46", "#99D580", "#5D5D5D", "#111111"];

function setupSwatches(e) {
    for (let i = 0; i < e.childNodes.length; i++) {
        e.childNodes[i].style.background = e.childNodes[i].getAttribute("data-colour");
        e.childNodes[i].addEventListener("click", function () {
            colour = this.getAttribute("data-colour");
            m.redraw();
            update();
        });
    }
}

function update() {
    callback(colour, lightText);
}


const Colour_Picker = {
    oninit: function (vnode) {
        colour = vnode.attrs.colour || "#2F53AA";
        lightText = vnode.attrs.lightText;
        callback = vnode.attrs.callback;
    },
    view: function () {
        return [
            m(".colour-picker", [
                m(".flex-row", [
                    m(".text-toggle", {
                        onclick: function () {
                            lightText = !lightText;
                            update();
                        },
                        style: ((lightText ? "color: #fff;" : "color: #000;") + (colour ? " background: " + colour + ";" : ""))
                    }, m("i.icon-font-colour")),
                    m("input[type=text][placeholder=#C0FF33]", {
                        oninput: function () {
                            colour = this.value;
                            update();
                        }, value: colour
                    }),
                    m(".pick-colour", {
                        onclick: function () {
                            showSwatches = !showSwatches;
                        }
                    }, m("i.icon-picker"))
                ]),
                showSwatches ? m(".swatches flex-row justify-between", {
                    oncreate: function (e) {
                        setupSwatches(e.dom);
                    }
                }, swatches.map(function (swatch) {
                    return [
                        m("span", {"data-colour": swatch})
                    ]
                })) : ""
            ])
        ];
    }
};
export default Colour_Picker;
