﻿import m from "mithril";
import Loading from "../../shared/loading";
import Page_Unit_Scorm from "./scorm/scorm";
import Page_Unit_Event from "./event/event";
import Page_Unit_Form from "./form/form";
import Page_Unit_Certificate from "./certificate/certificate";
import Page_Unit_Document from "./document/document";
import Page_Unit_Action from "./action/action";
import Page_Unit_Approval from "./approval/approval";
import Page_Unit_Download from "./download/download";
import Page_Unit_Upload from "./upload/upload";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import {UnitTypes} from "../values";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

let loading = false;
let unitClass = null;
let unit = null;

function loadUnit(id) {
    loading = true;
    Requester.get(Gateway.admin + "units/" + id)
        .then(function (result) {
            unitClass = result.class;
            unit = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}
let currentPage = "";
const Page_Unit = {
    oninit: function (vnode) {
        loadUnit(vnode.attrs.id);
        currentPage = "units";
    },
    view: function () {
        return [
            !loading ? [
                unit ? [
                    unitClass.indexOf(UnitTypes.scorm) !== -1 ? m(Page_Unit_Scorm, {unit: unit})
                        : unitClass.indexOf(UnitTypes.event) !== -1 ? m(Page_Unit_Event, {unit: unit})
                            : unitClass.indexOf(UnitTypes.form) !== -1 ? m(Page_Unit_Form, {unit: unit})
                                : unitClass.indexOf(UnitTypes.cert) !== -1 ? m(Page_Unit_Certificate, {unit: unit})
                                    : unitClass.indexOf(UnitTypes.doc) !== -1 ? m(Page_Unit_Document, {unit: unit})
                                        : unitClass.indexOf(UnitTypes.action) !== -1 ? m(Page_Unit_Action, {unit: unit})
                                            : unitClass.indexOf(UnitTypes.approval) !== -1 ? m(Page_Unit_Approval, {unit: unit})
                                                : unitClass.indexOf(UnitTypes.download) !== -1 ? m(Page_Unit_Download, {unit: unit})
                                                    : unitClass.indexOf(UnitTypes.upload) !== -1 ? m(Page_Unit_Upload, {unit: unit})
                                                        : [
                                                            m("div", i18n.t("invalid_unit_type")),
                                                            m(m.route.Link, {href: RouterService.getUnitsUrl()}, i18n.t("back_to_all_units"))
                                                        ]
                ] : m(".text-gray padding", i18n.t("invalid_unit"))
            ] : m(Loading)
        ];
    }
};
export default Page_Unit;
