﻿import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import SessionController from "../../../shared/session";

let loading = false;
let unit = null;
let previewUnit = null;

function loadUnit() {
    loading = true;
    Requester.get(Gateway.admin + "units/" + unit.id)
        .then(function (result) {
            previewUnit = null;
            previewUnit = result.properties;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}
let closeCallback;
const Component_Unit_Scorm_Preview = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        loadUnit();
        closeCallback = vnode.attrs.closeCallback;
    },
    onremove: function () {
        unit.selected = false;
    },
    view: function (vnode) {
        return [
            m(".flex-auto", [
                !loading ?
                    m(".scorm-preview", previewUnit && previewUnit.scormSource ?
                        m("iframe", {
                            src: SessionController.session.environment.storageUrl + "/scorm-player/scorm_preview_player.html",
                            onload: function () {
                                this.contentWindow.postMessage({
                                    method: "activate",
                                    data: {source: previewUnit.scormSource, version: previewUnit.scormVersion}
                                }, SessionController.session.environment.storageUrl)
                            }
                        })
                        : m(".content-box flex-auto", i18n.t("cant_load"))
                    )
                    : [i18n.t("loading"), m(".loading-dots")]
            ])
        ];
    }
};
export default Component_Unit_Scorm_Preview;
