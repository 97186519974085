﻿import i18n from "../../shared/i18n/i18n";
import SharedConstants from "../shared_constants";

const DateUtilities = {
	getFormattedDate: function (date) {
		if (!date)
			return "";

		return new Date(date).toLocaleTimeString(i18n.locale, { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "2-digit" });
	},
	getFormattedDateFromUTC: function (date) {
		if (!date)
			return "";

		if(!date.endsWith("Z"))
			date += "Z"
		return new Date(date).toLocaleTimeString(i18n.locale, { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "2-digit" });
	},
	parseUTC: function (date) {
		if (!date)
			return "";

		// The date is assumed to be the Clock time in UTC
		// Date will add the Browsers timezone. We need to strip 
		// the timezone without affecting the clock time. 
		let dateParsed = new Date(date)
		let userTimezoneOffset = dateParsed.getTimezoneOffset() * 60000;
		let isoString = new Date(dateParsed.getTime() - userTimezoneOffset).toISOString()
		return isoString.substring(0, isoString.indexOf("."))
	},
	getLocaleDateString: function (language) {
        return SharedConstants.dateFormats[language] || "DD/MM/YYYY";
    },
	getISODateInLocalTimeZone: function(dateTime) {
        const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
		const dateTimeWithoutTimeZoneOffset = new Date(dateTime.getTime() - timeZoneOffsetMinutes*60000);
		return dateTimeWithoutTimeZoneOffset.toISOString();
	}
}

export default DateUtilities;
