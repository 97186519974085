﻿import SessionController from '../session';
import UrlUtilities from '../utilities/url_utilities';

let OrgFileService = {
    containerName: "tenant",
    imageFolderName: "images",
    getLogoPath: function (orgId, fileName) {
        if (!SessionController.session || !SessionController.session.environment)
            return "";

        return UrlUtilities.combinePath(SessionController.session.environment.storageUrl, OrgFileService.containerName,
            orgId ? orgId : SessionController.session.environment.id, OrgFileService.imageFolderName, fileName ? fileName : SessionController.session.environment.logoName);
    },
    getBackgroundPath: function () {
        if (!SessionController.session || !SessionController.session.environment)
            return "";

        return UrlUtilities.combinePath(SessionController.session.environment.storageUrl, OrgFileService.containerName,
            SessionController.session.environment.id, OrgFileService.imageFolderName, SessionController.session.environment.backgroundName);
    }
}

export default OrgFileService;
